import { InfinitePaginationParams, PaginationData } from './common'

export enum ConversationSenderType {
  Human = 1,
  Bot = 2,
}

export enum ConversationMessageMode {
  Normal = 'normal',
  Quiz = 'quiz',
}

export type ConversationMessageType =
  | 'welcome_message'
  | 'ai_text_answer'
  | 'ai_tool_use_answer'
  | 'ai_escalation_proposal'
  | 'assistant_escalation_answer'
  | 'assistant_faq_answer'
  | 'user_escalation'
  | 'user_faq_submission'
  | 'user_text_message'
  // local type
  | 'assistant_faq_answer_running'

export enum ConversationMessageFeedback {
  Negative = 1,
  Positive = 2,
}

export interface EscalationCaseOther {
  value: string | string[]
  type: 'short_text' | 'phone' | 'file'
}

export interface EscalationCase {
  email: string
  subject: string
  description: string
  others: Record<string, EscalationCaseOther>
}

export interface ConversationMessageSource {
  title: string
  source: string
}

export interface BodyPartEmail {
  body_part_type: 'email'
  email: string
}

export interface BodyPartEscalationProposal {
  body_part_type: 'escalation_proposal'
  button_text: string
}

export interface BodyPartEscalationCase {
  body_part_type: 'escalation_case'
  escalation_case: EscalationCase
}

export interface BodyPartLink {
  body_part_type: 'link'
  link: string
}

export interface BodyPartStart {
  body_part_type: 'message_start'
}

export interface BodyPartPhone {
  body_part_type: 'phone'
  phone: string
}

export interface BodyPartSource {
  body_part_type: 'source'
  source: ConversationMessageSource
}

export interface BodyPartText {
  body_part_type: 'text'
  text: string
}

export interface BodyPartTool {
  body_part_type: 'tool'
  header: string
  details: string
}

export interface BodyPartModeChange {
  body_part_type: 'mode_change'
  conversation_mode: ConversationMessageMode
}

export interface BodyPartFollowUpQuestions {
  body_part_type: 'followup_questions'
  followup_questions: string[]
}

export type ConversationMessageBodyPart =
  | BodyPartEmail
  | BodyPartEscalationProposal
  | BodyPartEscalationCase
  | BodyPartLink
  | BodyPartStart
  | BodyPartPhone
  | BodyPartSource
  | BodyPartText
  | BodyPartTool
  | BodyPartModeChange
  | BodyPartFollowUpQuestions

export interface ConversationMessage {
  id: string
  timestamp: string
  sender_type: ConversationSenderType
  message_type: ConversationMessageType
  feedback?: ConversationMessageFeedback | null
  body_parts: ConversationMessageBodyPart[]
  conversation_mode?: ConversationMessageMode
}

export interface Conversation {
  id: string
  start_timestamp: string
  end_timestamp?: string
  last_message_timestamp: string
  last_message: string
  escalation_case_submitted: boolean
}

export interface ConversationDetail extends Conversation, PaginationData {
  messages: ConversationMessage[]
}

export interface InfiniteConversationsParams extends InfinitePaginationParams {
  conversationId: string
}

export interface CreateConversationInput {
  lti_info?: {
    lti_course_id?: number
    lti_section_id?: string
  }
  user_session_metadata?: {
    browser?: string
    ip_address?: string
    language?: string
    launch_url?: string
    operation_system?: string
    resolution?: string
  }
  shortcut_button_id?: string
}

export type StreamingMessage = ConversationMessageBodyPart & {
  data: ConversationMessage[]
}

export const VOTABLE_MESSAGE_TYPES: ConversationMessageType[] = [
  'ai_text_answer',
  'ai_escalation_proposal',
  'assistant_faq_answer',
]

export const READABLE_MESSAGE_TYPES: ConversationMessageType[] = [
  ...VOTABLE_MESSAGE_TYPES,
  'assistant_escalation_answer',
]
