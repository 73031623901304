import { createContext, FC, ReactNode, useContext, useEffect, useState } from 'react'
import { log } from '../utils/log'

interface UserData {
  name: string | null
  email: string | null
  lmsUserId: string | null
  roles: string[] | null
}

const useContextHook = () => {
  const [launchUrl, setLaunchUrl] = useState('')
  const [assistantId, setAssistantId] = useState('')
  const [courseId, setCourseId] = useState<number>()
  const [sectionId, setSectionId] = useState('')
  const [userData, setUserData] = useState<UserData>({
    name: null,
    email: null,
    lmsUserId: null,
    roles: null,
  })
  const [conversationId, setConversationId] = useState('')
  const [fullscreen, setFullscreen] = useState(false)
  const [inputFocused, setInputFocused] = useState(false)

  // We previously used userData.ready to determine if we should do the "normal" (injector) sign-in.
  // However, with temporary-token-based sign-in, we still need to get the user data from the back-end,
  // so we can't rely on the presence of userData to know whether to do a normal sign-in.
  // Instead, we use an additional state variable to track the sign-in method.
  const [shouldDoSignIn, setShouldDoSignIn] = useState<'injector' | 'lti' | null>(null)

  useEffect(() => {
    const local = window.sessionStorage.getItem('conversation')
    if (assistantId) {
      log('[ConversationProvider.tsx] [useEffect] [assistantId] Setting conversationId from session storage')
      const conversationId = local ? (JSON.parse(local)?.[assistantId] as string) || '' : ''
      setConversationId(conversationId)
      log(
        '[ConversationProvider.tsx] [useEffect] [assistantId] Set conversationId from session storage:',
        conversationId,
      )
    }
  }, [assistantId])

  const handleConversationId = (val: string) => {
    log('[ConversationProvider.tsx] [handleConversationId] Setting conversationId to:', val)
    setConversationId(val)
    const currentConversations = window.sessionStorage.getItem('conversation')
    const updatedConversations = currentConversations
      ? { ...JSON.parse(currentConversations), [assistantId]: val }
      : { [assistantId]: val }
    window.sessionStorage.setItem('conversation', JSON.stringify(updatedConversations))
    log(
      '[ConversationProvider.tsx] [handleConversationId] Updated conversation in session storage:',
      updatedConversations,
    )
  }

  return {
    launchUrl,
    setLaunchUrl,
    assistantId,
    setAssistantId,
    courseId,
    setCourseId,
    sectionId,
    setSectionId,
    userData,
    setUserData,
    conversationId,
    setConversationId: handleConversationId,
    fullscreen,
    setFullscreen,
    inputFocused,
    setInputFocused,
    shouldDoSignIn,
    setShouldDoSignIn,
  }
}

type StorageHook = ReturnType<typeof useContextHook>

const ConversationContext = createContext<StorageHook>({} as any)

type Props = {
  children?: ReactNode
}

const ConversationProvider: FC<Props> = ({ children }) => {
  const props = useContextHook()
  return <ConversationContext.Provider value={props}>{children}</ConversationContext.Provider>
}

export const useConversationContext = () => useContext(ConversationContext)

export default ConversationProvider
